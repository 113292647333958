import React, { useEffect, useState } from 'react';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import Swal from 'sweetalert2';
// import pdfTemplate from "../assests/pdf/CertificateofDonation.pdf"; // Assuming your certificate PDF
import pdfTemplate from "../assests/pdf/CertificateDonation.pdf"; // Assuming your certificate PDF
var Buffer = require('buffer/').Buffer

const PaymentSuccess = () => {
    const query = new URLSearchParams(window.location.search);
    const data = query.get('d');

    let decodeData = Buffer.from(data, 'base64');
    decodeData = decodeData.toString('utf8');
    const { name, amount } = JSON.parse(decodeData)

    console.log("decode name", decodeData);

    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        // Call the PDF generation function
        if (name && amount) {
            generatePDF(name, amount);
        }
    }, [name, amount]);

    const generatePDF = async (name, amount) => {
        try {
            const currentDate = new Date().toLocaleDateString('en-IN', {
                day: '2-digit', month: 'long', year: 'numeric'
            });
            const formattedDateWithPrefix = `on ${currentDate}`;


            const formattedAmount = `Rs. ${new Intl.NumberFormat('en-IN', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(amount)}`;

            const existingPdfBytes = await fetch(pdfTemplate).then(res => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
            const normalFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
            const page = pdfDoc.getPages()[0];
            const pageWidth = page.getWidth();

            const nameTextWidth = boldFont.widthOfTextAtSize(name, 20);
            const dateTextWidth = normalFont.widthOfTextAtSize(formattedDateWithPrefix, 15);
            const amountTextWidth = boldFont.widthOfTextAtSize(formattedAmount, 20);

            const nameX = (pageWidth - nameTextWidth) / 2;
            const dateX = (pageWidth - dateTextWidth) / 2;
            const amountX = (pageWidth - amountTextWidth) / 2;

            // Add text to the PDF
            page.drawText(name, {
                x: nameX,
                y: 395,
                size: 20,
                font: boldFont,
                color: rgb(0, 0, 0),
            });

            page.drawText(formattedDateWithPrefix, {
                x: dateX,
                y: 375,
                size: 15,
                font: normalFont,
                color: rgb(0, 0, 0),
            });

            page.drawText(formattedAmount, {
                x: amountX,
                y: 320,
                size: 20,
                font: boldFont,
                color: rgb(0, 0, 0),
            });

            // Save the PDF and create a download link
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);

            // Save the URL in the state to be used for download button
            setPdfUrl(url);

        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    return (
        <div className="flex flex-col justify-center items-center min-h-screen p-4 lg:mx-80">
            <div className="bg-white shadow-lg rounded-lg p-8 w-full lg:mx-40 mx-auto">
                <h1 className="text-3xl font-bold text-center text-[#F17951]">Payment Successful!</h1>
                <h2 className="text-xl text-center mt-4">Thank you, {name}!</h2>
                <p className="text-lg text-center">We have received a donation of Rs.{amount}.</p>
                <div className="flex justify-center mt-8">
                    {pdfUrl ? (
                        <a href={pdfUrl} download="Certificate_of_Donation.pdf" className="bg-[#F17951] text-white py-2 px-6 rounded-lg text-lg">
                            Download Certificate
                        </a>
                    ) : (
                        <p>Generating your certificate...</p>
                    )}
                </div>
            </div>
        </div>

    );
};

export default PaymentSuccess;

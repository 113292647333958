import React from "react";
import Image1 from "../assests/Gaushala_Blogs/blog1.png"
import Image2 from "../assests/Gaushala_Blogs/blog2.png"
import Image3 from "../assests/Gaushala_Blogs/blog3.png"
import Image4 from "../assests/Gaushala_Blogs/blog4.png"
import Image5 from "../assests/Gaushala_Blogs/blog5.png"
import Image6 from "../assests/Gaushala_Blogs/blog6.png"
import Image7 from "../assests/Gaushala_Blogs/blog7.png"

const blogs = [
    {
        title: "The Role of Gaushalas in Indian Culture",
        content:
            "Gaushalas, or cow shelters, have played a significant role in Indian culture and traditions. The cow is considered sacred, often referred to as 'Gau Mata,' symbolizing sustenance and motherhood. Gaushalas not only protect cows but also promote the traditional values associated with animal welfare. At Shree Vrihadnarayan Gaushala, we follow these traditions, providing a safe and caring environment for cows. We ensure that each cow, whether young or old, is treated with love and respect. This blog will explore the ancient roots of Gaushalas and how they continue to contribute to modern society by fostering a compassionate, sustainable lifestyle.",
        image:Image1,
    },
    {
        title: "Sustainable Farming Practices Through Cow Protection",
        content:
            "Cow dung and urine are often seen as waste, but in organic farming, they are valuable resources. At Shree Vrihadnarayan Gaushala, we utilize these resources to create biofertilizers and natural pesticides that contribute to healthy, sustainable farming practices. By supporting a Gaushala, you are also supporting environmentally friendly farming techniques. Learn how the cow’s role extends beyond milk production to being a vital contributor to sustainable agriculture.",
        image:Image2,
    },
    {
        title: "The Healing Benefits of Panchagavya",
        content:
            "Panchagavya, a traditional preparation made from cow products (milk, curd, ghee, cow urine, and dung), is believed to have healing and purifying properties. This ancient Ayurvedic concoction is said to boost immunity and promote overall health. In this blog, we’ll discuss the science behind Panchagavya, how it’s made, and why it's gaining popularity in wellness circles. Shree Vrihadnarayan Gaushala supports the traditional practices that utilize every aspect of the cow, ensuring no part goes to waste.",
        image:Image3,
    },
    {
        title: "How You Can Support the Gaushala Movement",
        content:
            "Running a Gaushala like Shree Vrihadnarayan Gaushala involves dedication, resources, and support from the community. In this blog, we’ll share how you can help, whether through volunteering, donating, or adopting a cow. We believe that every small effort counts, and together, we can make a big difference in the lives of cows and contribute to a more compassionate world. Learn the various ways you can be involved in supporting and expanding the Gaushala movement.",
        image: Image4,
    },
    {
        title: "Cow Conservation and Its Environmental Impact",
        content:
            "Cows are essential to maintaining the balance of the ecosystem. This blog will dive into the environmental benefits of conserving indigenous cow breeds, reducing methane emissions through proper care, and utilizing cow-based byproducts for eco-friendly solutions. At Shree Vrihadnarayan Gaushala, we focus on preserving indigenous breeds and reducing environmental impact by promoting eco-friendly practices. Join us as we explore the connection between cow conservation and environmental sustainability.",
        image: Image5,
    },
    {
        title: "Stories of Rescue: How We Save Cows from Slaughter",
        content:
            "One of the most heartfelt aspects of running a Gaushala is rescuing cows from slaughterhouses and providing them with a safe home. In this blog, we’ll share real-life stories of how Shree Vrihadnarayan Gaushala has rescued cows, sometimes from dire circumstances, and given them a second chance at life. These stories of compassion will inspire you to get involved and be a part of this life-saving mission.",
        image:Image6,
    },
    {
        title: "The Importance of Cow Milk in Ayurveda",
        content:
            "In Ayurveda, cow milk is considered 'amrit' (nectar). It is rich in nutrients and is believed to have calming and nourishing properties. We’ll explore how cow milk is used in Ayurvedic remedies, its benefits for physical and mental health, and how you can incorporate it into your daily diet. At Shree Vrihadnarayan Gaushala, we promote healthy living through the traditional knowledge of Ayurveda, ensuring that our cows are happy and healthy, which, in turn, ensures the quality of their milk.",
        image: Image7,
    },
];

const BlogSection = () => {
    return (
        <div className="bg-gray-50 py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-extrabold text-[#F17951]  mb-8 text-center">
                    Our Latest Blogs
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {blogs.map((blog, index) => (
                        <div
                            key={index}
                            className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
                        >
                            <img
                                src={blog.image}
                                alt={blog.title}
                                className="h-48 w-full object-cover"
                            />
                            <div className="p-6">
                                <h3 className="text-xl font-semibold text-gray-800 mb-4">
                                    {blog.title}
                                </h3>
                                <p className="text-gray-600 mb-4">{blog.content}</p>
                                {/* <button className="text-indigo-600 font-bold hover:text-indigo-400">
                                    Read More
                                </button> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BlogSection;
